
import Vue from 'vue'
import Component from 'vue-class-component'
// import { UserRoles } from '@/typings/UserTypings'

import { mapGetters } from 'vuex'

@Component({
  name: 'VideoInsruction',
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  }
})
export default class VideoInsruction extends Vue {
  userRole = ''

  created() {
    this.userRole = this.$store.getters['auth/userRole']
  }
}
